<template>
    <div>
       <Header />
        <mdb-parallax style="margin-top: -13px"
            src="/img/bgs/bg7.jpg"
            height="full"
            factor="1"
            >
            <mdb-mask overlay="blue-slight" flexCenter>
                <mdb-row class="mb-5 pb-5 text-center white-text">
                <mdb-col md="12" xl="12" class="mx-auto">
                    <h1 class="display-4 font-weight-bold animated fadeInDown">Proyectos</h1>
                </mdb-col>
                </mdb-row>
            </mdb-mask>
        </mdb-parallax>
        <mdb-container class="mt-5">
                <mdb-row class="mt-3">
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro1.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="text-center">
                            <h6 class="font-weight-bold mt-2 mb-5 ar-text">Monitoreo de Biodiversidad</h6>
                            <mdb-btn style="margin-top: 3px;" color="primary" rounded @click.native="toggleServiceModal('mon')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro2.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-2 ar-text">Modelación sobre la Dispersión de Gases (AERMOD)</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('mod')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro3.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-5 ar-text">Análisis Hidrológicos</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('anhi')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/eva.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-2 ar-text">Evaluación ambiental del mejoramiento de sistemas de agua potable</h6>
                            <mdb-btn style="margin-top: 8px;" color="primary" rounded @click.native="toggleServiceModal('eva')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro4.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-0 ar-text">Análisis de Bioacumulación de Gases Incondensables</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('anbi')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro5.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-0 ar-text">Análisis de Pasivos Ambientales</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('anpa')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro6.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-3 ar-text">Regulación Normativa Vial</h6>
                            <mdb-btn style="margin-top: 8px;" color="primary" rounded @click.native="toggleServiceModal('reg')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro7.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-3 ar-text">Captura de CO2</h6>
                            <mdb-btn style="margin-top: 8px;" color="primary" rounded @click.native="toggleServiceModal('cap')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro8.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-4 ar-text">Conectividad de Paisajes Forestales</h6>
                            <mdb-btn style="margin-top: 15px;" color="primary" rounded @click.native="toggleServiceModal('con')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro9.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-3 ar-text">Restauración, Conservación y Manejo de manglar</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('man')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro10.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-3 ar-text">Análisis de Permeabilidad e Infiltración en Contextos Geotérmicos</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('per')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
                    <mdb-col sm="12" md="12" lg="4" xl="3" class="mb-4">
                        <mdb-view class="overlay rounded z-depth-2">
                            <img src="/img/pro11.jpg" alt="sample photo" class="img-fluid"/>
                            <a>
                                <mdb-mask waves overlay="white-slight"/>
                            </a>
                        </mdb-view>
                        <mdb-card-body class="pb-0 text-center">
                            <h6 class="font-weight-bold mt-2 mb-3 ar-text">Regulación Normativa Industrial en el Área Alimenticia</h6>
                            <mdb-btn color="primary" rounded @click.native="toggleServiceModal('ali')">Ver Detalles</mdb-btn>
                        </mdb-card-body>
                    </mdb-col>
            </mdb-row>
            </mdb-container>
        <mdb-modal size="lg" :show="modal" @close="toggleServiceModal('')">
            <mdb-modal-header style="background-color: #11619d; color: white;">
                <mdb-modal-title style="font-weight: bold;">{{ selected.name != undefined ? selected.name : '' }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row>
                    <mdb-col class="text-left">
                        <p class="ar-text text-justify" v-for="parr in selected.p" :key="parr" v-html="parr"></p>
                        <br>
                    </mdb-col>   
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer>
                <button class="btn btn-arvore" @click="toggleServiceModal('')">Cerrar</button>
            </mdb-modal-footer>
        </mdb-modal>
       <Footer /> 
    </div>
</template>
<script>
    import Header from "./Header";
    import Footer from "./Footer";

    import  { mdbContainer, mdbRow, mdbCol, mdbCard, mdbLightbox, mdbMasonry, mdbMasonryItem, mdbBtn, mdbMask, mdbParallax, mdbView, mdbCardBody, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter } from "mdbvue";

    export default {
        name: "Clients",
        components: {
            Header,
            Footer,
            mdbContainer,
            mdbRow, 
            mdbCol, 
            mdbCard,
            mdbLightbox,
            mdbMasonry,
            mdbMasonryItem,
            mdbBtn,
            mdbMask,
            mdbParallax,
            mdbView,
            mdbCardBody,
            mdbModal, 
            mdbModalHeader, 
            mdbModalTitle, 
            mdbModalBody, 
            mdbModalFooter
        },
        data(){
            return {
                imgs: [
                    "/img/content2/monito.png",
                    "/img/content2/modelo.png",
                    "/img/content2/analisis.png",
                    "/img/content2/bioacumulacion.png",
                    "/img/content2/pasivos.png",
                    "/img/content2/vial.png",
                    "/img/content2/co2.png",
                    "/img/content2/conectividad.png",
                    "/img/content2/mangle.png",
                    "/img/content2/permeabilidad.png",
                    "/img/content2/alimenticia.png"
                ],
                index: 0,
                visible: false,
                team: [
                   /* 
                    Restauración y Manejo de Zona de Mangle
                    Análisis de Permeabilidad e Infiltración en Contextos Geotérmicos
                    Regulación Normativa Industrial en el Área Alimenticia */
                    {
                        id: "mon",
                        name: "Monitoreo de Biodiversidad",
                        description: "La gestión regulatoria ambiental se refiere al conjunto de acciones y procesos llevados a cabo para cumplir con las regulaciones y normativas ambientales vigentes. En Arvore le damos seguimiento a las leyes, reglamentos y políticas relacionadas con el medio ambiente, con el objetivo de garantizar el cumplimiento de los requisitos legales en términos de protección ambiental. Dentro de los servicios que ofrece Arvore, se encuentran la obtención de permisos, la evaluación y gestión de impactos ambientales (MIA´s, EDA´s etc.), planes de manejo, entre otras.",
                        p: [
                            "Estudio de monitoreo de flora y fauna en el Campo Geotérmico Los Azufres, Mich.",
                            "<b style='font-weight: 800;'>No. CFE-0923-CSSAN-0018-2019</b>",
                            "El estudio de monitoreo de flora y fauna en el Campo Geotérmico Los Azufres, Michoacán, tuvo como objetivo principal evaluar y comprender la biodiversidad presente en esta área geotérmica. Mediante el uso de técnicas de muestreo y seguimiento, analizamos la composición y distribución de la flora y fauna, así como su respuesta a las condiciones ambientales específicas del sitio. Este estudio nos proporcionó información invaluable para la conservación de especies, el manejo adecuado de los recursos naturales y la implementación de medidas de mitigación y preservación del ecosistema. A través de nuestro compromiso con la investigación científica y el cuidado del medio ambiente, buscamos contribuir a un desarrollo sustentable y equilibrado en esta importante región."
                        ]
                    },
                    {
                        id: "mod",
                        name: "Modelación sobre la Dispersión de Gases (AERMOD)",
                        description: "Los Proyectos orientados en soluciones basados en la naturaleza (NBS) por sus siglas en ingles, son iniciativas que utilizan soluciones naturales y ecosistemas saludables para abordar desafíos ambientales y sociales. Estos proyectos se enfocan en la conservación, restauración, conectividad y gestión sostenible de la biodiversidad y los ecosistemas, como bosques, humedales, ríos y áreas costeras. Nuestra propuesta busca aprovechar los servicios ecosistémicos, como la captura de carbono, la mejora de la calidad del agua y el hábitat para la biodiversidad a fin de promover la resiliencia y la sostenibilidad. En Arvore, pretendemos que todos nuestros proyectos sean vinculantes e integrales, ofreciendole al cliente una garantía de calidad e impacto en cada uno de los proyectos que se realizan.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b>  Análisis de las concentraciones de gases incondensables atmosféricos a través del uso de modelo de dispersión “AERMOD”",
                            "<b style='font-weight: 800;'>No:  CFE-0054-CSSAN-0003-2022</b>",
                            "Nuestro proyecto se centró en el desarrollo de un modelo de dispersión de gases incondensables en el Campo Geotérmico Los Azufres, utilizando la metodología AERMOD. Este modelo nos permitió evaluar la dispersión y concentración de los gases emitidos durante las actividades geotérmicas en la zona. A través de la recopilación de datos meteorológicos, características topográficas y propiedades de los gases, pudimos predecir con precisión la trayectoria y el impacto potencial de estas emisiones en el medio ambiente. Este estudio proporcionó información esencial para la gestión de riesgos, la toma de decisiones y la implementación de medidas de mitigación adecuadas para proteger la calidad del aire y la salud de las comunidades cercanas."
                        ]
                    },
                    {
                        id: "anhi",
                        name: "Análisis Hidrológicos",
                        description: "Los estudios ambientales en el sector energético son análisis y evaluaciones que se realizan para comprender, monitorear y mitigar los impactos ambientales de las actividades relacionadas con la producción, transporte, distribución y uso de energía. Los estudios que ofrecemos en Arvore examinan aspectos como la dispersion de gases en aire, el consumo de recursos naturales, las emisiones de gases de efecto invernadero y su acomulación en matrices como suelo, agua y vegetación, el impacto en la biodiversidad y los ecosistemas, estudios de permeabilidad e infiltración y estudios en la hidrología subterranea y superficial de los contextos ambientales de producción energética. Nuestro objetivo es garantizar una gestión sostenible de la energía y minimizar los efectos negativos en el medio ambiente.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b> Efecto acumulativo que producen las emisiones derivadas del aprovechamiento geotérmico (CO2, H2S y CH4) sobre la Hidrología superficial en el campamento Geotérmico Los Azufres, Mich.",
                            "<b style='font-weight: 800;'>No. CFE-0927-CSSAN-0007-2019</b>",
                            "Nuestro proyecto se enfocó en investigar los efectos acumulativos de las emisiones derivadas del aprovechamiento geotérmico, incluyendo CO2, H2S y CH4, sobre la hidrología superficial. Nos dedicamos a analizar cómo estas emisiones afectan los cuerpos de agua superficiales, como ríos, lagos y arroyos, dentro del área de influencia del proyecto geotérmico."
                        ]
                    },
                    {
                        id: "eva",
                        name: "Evaluación ambiental del mejoramiento de sistemas de agua potable",
                        description: "Los estudios ambientales en el sector energético son análisis y evaluaciones que se realizan para comprender, monitorear y mitigar los impactos ambientales de las actividades relacionadas con la producción, transporte, distribución y uso de energía. Los estudios que ofrecemos en Arvore examinan aspectos como la dispersion de gases en aire, el consumo de recursos naturales, las emisiones de gases de efecto invernadero y su acomulación en matrices como suelo, agua y vegetación, el impacto en la biodiversidad y los ecosistemas, estudios de permeabilidad e infiltración y estudios en la hidrología subterranea y superficial de los contextos ambientales de producción energética. Nuestro objetivo es garantizar una gestión sostenible de la energía y minimizar los efectos negativos en el medio ambiente.",
                        p: [        
                            "<b style='font-weight: 800;'>Proyecto:</b> Informe preventivo Aplicado al proyecto « Construcción, ampliación, equipamiento y mejoramiento del sistema de agua potable de la localidad de Santiago Tzipijo, municipio de Tzintzuntzan, Michoacán de Ocampo »",
                            "<b style='font-weight: 800;'>No: CDI/DMICH/CT/PROII/057/2015</b>",
                            "El proyecto tuvo como objetivo mejorar el suministro de agua potable en dicha localidad. El informe preventivo aplicado al proyecto, evaluó y analizó los posibles riesgos, impactos ambientales y sociales que podrían surgir durante la ejecución del proyecto.",
                            "Buscamos identificar y anticipar los problemas potenciales para tomar medidas preventivas y mitigar cualquier impacto negativo en el entorno. Esto implicó evaluar aspectos como la disponibilidad de recursos hídricos, los posibles impactos en la flora y fauna local, el manejo de desechos, la participación de la comunidad y la gestión adecuada de los trabajos de construcción."
                        ]
                    },
                    {
                        id: "anbi",
                        name: "Análisis de Bioacumulación de Gases Incondensables",
                        description: "En Arvore nos especializamos como desarrolladores de proyectos en la captura de CO2 y se refiere a la elaboración y ejecución de proyectos que compensen y/o capturen las emisiones de dióxido de carbono (CO2) generadas por una actividad o proceso. Esto a través de la inversión en proyectos o acciones que reduzcan o capturen CO2 equivalente en otra parte. Los proyectos que Arvore ofrece, pueden incluir la reforestación, la conservación y restauración de bosques, la conectividad del paisaje, la ganadería y la agricultura regenerativa, entre otros. Este proceso lo realizamos en colaboracion con certificadores y verificadores internacionales a fin de incidir directamente sobre el Mercado Voluntario de Carbono y su ecosistema.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b> Efecto acumulativo que producen las emisiones derivadas del aprovechamiento geotérmico (CO2, H2S y CH4) sobre los componentes ambientales, vegetación, suelo é hidrología subterránea dentro del área del proyecto en el campo Geotérmico Loa Azufres, Mich.",
                            "<b style='font-weight: 800;'>No: CFE-0054-CSSAN-0003-2022</b>",
                            "Nuestro estudio se enfocó en analizar los  efectos acumulativos de las emisiones derivadas del aprovechamiento geotérmico, específicamente CO2, H2S y CH4, sobre los componentes ambientales clave en el área del proyecto en el Campo Geotérmico Los Azufres, Michoacán. Nos centramos en evaluar el impacto de estas emisiones en la vegetación, el suelo y la hidrología subterránea. A través de un exhaustivo monitoreo y análisis de datos, pudimos comprender cómo estas emisiones afectan la salud y la calidad de estos componentes ambientales vitales. Nuestro objetivo porporciono información precisa y detallada que permita una gestión adecuada y una toma de decisiones informada para mitigar los impactos negativos y promover prácticas sostenibles en el aprovechamiento geotérmico."
                        ]
                    },                    
                    {
                        id: "anpa",
                        name: "Análisis de Pasivos Ambientales",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "<b>Proyecto:</b> Análisis sobre la presencia de Bifenilos Policrolados en suelos proximales de la Unidad 18, dentro del área del proyecto en el campo Geotérmico Los Azufres, Mich. No: CFE-0054-CSSAN-0003-2022",
                            "<b style='font-weight: 800;'>No: CFE-0054-CSSAN-0003-2022</b>",
                            "El objetivo principal fue evaluar y comprender el nivel de contaminación por estos compuestos en la zona y sus posibles efectos en el entorno a partir de la actividad Geotérmica. Mediante técnicas de muestreo y análisis de muestras de suelo, se buscó obtener información precisa sobre la presencia y concentración de Bifenilos Policlorados, lo que permitió tomar medidas adecuadas para mitigar los impactos ambientales y proteger la salud de la comunidad local."
                        ]
                    },
                    {
                        id: "reg",
                        name: "Regulación Normativa Vial",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b> Manifestación de impacto ambiental en su modalidad regional; vías generales de comunicación, Aplicado al proyecto «Construcción del Libramiento de Huetamo, tramo del km 0+000 al km 4+840, en el municipio de Huetamo de Nuñez, Michoacán de Ocampo.»",
                            "<b style='font-weight: 800;'>No. SCT- MIA Reg-009-00-2017</b>",
                            "<b style='font-weight: 800;'>Proyecto:</b> Manifestación de impacto ambiental regional; en su modalidad vías generales de comunicación para el proyecto “modernización tipo b del camino: E.C. (Patzcuaro-Uruapan) Zirahuén, tramo del km 0+000 al km 5+00, en la localidad de Zirahuén, municipio de Salvador Escalante, Michoacán de Ocampo”",
                            "<b style='font-weight: 800;'>Promovente del Proyecto:</b> Junta de caminos del estado de Michoacán."
                        ]
                    },
                    {
                        id: "cap",
                        name: "Captura de CO2",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "Los proyectos de captura de CO2 se desarollan en colaboración con ejidos y comunidades en Michoacán, Jalisco y Colima y se centran en la reforestación, conservación y aprovechamiento sustentable de los recursos naturales en ecosistemas clave de la región. Estas iniciativas se desarrollan específicamente en la selva baja, los manglares y los bosques de coníferas presentes en las zonas altas de Michoacán y Jalisco.",
                            "En la selva baja, se llevan a cabo acciones de reforestación y conservación para fortalecer la captura de CO2 y preservar la biodiversidad única de este ecosistema. Mediante la plantación de especies nativas y el manejo adecuado del bosque, se busca crear corredores verdes que permitan la conexión entre áreas fragmentadas y favorezcer la creción de nichos necesarios para el mantenimiento de fauna.",
                            "En los manglares, se promueve la restauración y protección de estos valiosos humedales, estos ecosistemas costeros son reconocidos por su alta capacidad de almacenamiento de carbono y su conservación contribuye significativamente a la captura de CO2.",
                            "En las zonas altas de Michoacán y Jalisco, donde se encuentran bosques de coníferas, se implementan acciones de reforestación y manejo forestal sustentable. Estos bosques albergan una gran cantidad de carbono y su conservación es esencial para mitigar el cambio climático. A través de prácticas adecuadas de manejo, se busca promover la regeneración natural, evitar la deforestación y garantizar la sostenibilidad a largo plazo de estos ecosistemas.",
                            "La participación de las comunidades y ejidos en estos proyectos es fundamental, trabajando en estrecha colaboración con los miembros de estas comunidades, brindando capacitación y apoyo técnico para implementar acciones de captura de CO2 y fortalecer la gestión sostenible de los recursos naturales en sus territorios. Además, se establecen acuerdos que aseguran beneficios económicos y sociales para las comunidades, incentivando su compromiso y empoderamiento en la protección y uso responsable de los ecosistemas en los que habitan."
                        ]
                    },
                    {
                        id: "con",
                        name: "Conectividad de Paisajes Forestales",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "El proyecto tuvo como objetivo principal establecer corredores ecológicos que conecten áreas forestales en zonas geotérmicas, promoviendo la conservación de la biodiversidad  y restauración de los ecosistemas.",
                            "En estas áreas, la actividad geotérmica puede afectar el entorno natural, fragmentando los ecosistemas y limitando la movilidad de las especies. El proyecto busca mitigar estos impactos y fortalecer la conectividad entre los diferentes hábitats forestales, permitiendo que las especies se desplacen de manera segura y facilitando el intercambio genético.",
                            "Para lograr esto, se llevó a cabo un estudio detallado del paisaje y se identificaron los puntos clave para la creación de corredores ecológicos. Estos corredores se diseñan teniendo en cuenta los requisitos de las especies objetivo y las características del entorno geotérmico.",
                            "Se implementan medidas de restauración y conservación en los corredores, como la revegetación con especies nativas, la creación de estructuras para facilitar el movimiento de la fauna y la implementación de prácticas de manejo forestal sostenible."
                        ]
                    },
                    {
                        id: "man",
                        name: "Restauración, Conservación y Manejo de manglar",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b> Planes de manejo para las Unidades de Manejo para la Conservación de la Vida Silvestre Intensiva y extensiva el Manglitus, Lázaro Cardenas, Mich. SEMARNAT",
                            "<b style='font-weight: 800;'>No: MICH/GA/04/1484/2020</b>",
                            "<b style='font-weight: 800;'>MICH/GA/04/1488/2020</b>",
                            "<b style='font-weight: 800;'>Proyecto:</b> Manifestación de Impacto Ambiental para la implementación de la UMA de Mangle el Manglitus, Lázaro Cárdenas, Michoacán. SEMARNAT",
                            "<b style='font-weight: 800;'>No: MICH/GA/04/14694/2021</b>",
                            "Plan de Vigilacia y Control para la UMA de Mangle el Manglitus, Lázaro Cárdenas, Michoacán. SEMARNAT-PROFEPA"
                        ]
                    },
                    {
                        id: "per",
                        name: "Análisis de Permeabilidad e Infiltración en Contextos Geotérmicos",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "<b style='font-weight: 800;'>Estudio:</b> Estudio de infiltración y permeabilidad correspondiente a los suelos proximales al pozo AZ-85 del campo geotérmico los Azufres, Mich.",
                            "<b style='font-weight: 800;'>No: CFE-0054-CSSAN-0003-2022</b>",
                            "Utilizando técnicas de muestreo y análisis de suelos, evaluamos la capacidad de infiltración, la estructura del suelo y otros factores que influyen en el proceso de infiltración. También consideramos la relación entre la infiltración y las actividades geotérmicas cercanas, como las emisiones de gases y el aprovechamiento geotérmico.",
                            "Nuestro objetivo fue obtener una comprensión más completa de la hidrología local y proporcionar información relevante para la gestión y conservación de los recursos hídricos subterráneos en el Campo Geotérmico Los Azufres."
                        ]
                    },
                    {
                        id: "ali",
                        name: "Regulación Normativa Industrial en el Área Alimenticia",
                        description: "Las tecnologías renovables son aquellas que utilizan fuentes de energía natural y sostenible, que se regeneran rápidamente y que en principio no se agotan. En Arvore trabajamos de la mano de aliados estrategicos en el sector, impulsando y promoviendo el uso de energia fotovoltaica para diversos fines como la agricultura, iluminación urbana, transporte publico, entre otros. Tambien diseñamos y ejecutamos proyectos que estan orientados al mejoramiento de los procesos del tratamiento de agua, mediante la utilizacion de Biofiltros o Carrier´s, utilizados en los sistemas de tratamiento de aguas residuales diseñados para operar en reactores biológico de lecho movil o MBBR por sus siglas en ingles (Moving Bed Biological Reactor). Este tratamiento consiste en la degradación de la materia orgánica por parte de bacterias aerobias y aumenta la eficiencia en el tratamiento.",
                        p: [
                            "<b style='font-weight: 800;'>Proyecto:</b> Estudio de daño ambiental Aplicado al predio rústico localizado en la localidad “El Tigre”, municipio de Tzintzuntzan, Michoacán de Ocampo; Fabrica de dulces La Orquidea.",
                            "<b style='font-weight: 800;'>No: PIV.DC.PDA.071/2017</b>",
                            'Nuestro estudio se enfocó en evaluar el daño ambiental de una fabrica de dulces, en un predio rústico ubicado en la localidad "El Tigre", en el municipio de Tzintzuntzan, Michoacán de Ocampo. Nuestro objetivo fue analizar los impactos ambientales negativos a partir del procesamiento de la fruta para la elaboración de dulces, y con ello determinar su magnitud y alcance.',
                            "A través de un meticuloso proceso de investigación de campo y análisis de datos, examinamos los diferentes componentes del proceso de producción así como del ecosistema en el predio, incluyendo la flora, la fauna, los suelos y los recursos hídricos. También consideramos las actividades humanas que podrían haber contribuido al daño ambiental, como la deforestación, la contaminación o la degradación del suelo. Se propuso adisionalmente un programa de compensación ambiental, a partir de una reforestación en un área equivalente a la superficie del proyecto industrial. Por último se realizaron recomendaciones para el tratamiento de 1 tonelada mensual de desechos orgánicos de fruta."
                        ]
                    },
                ],
                selected: {},
                modal: false
                
            }
        },
        methods: {
            showLightbox(index) {
                this.index = index;
                this.visible = true;
            },
            handleHide() {
                this.visible = false;
            },
            toggleServiceModal(service){
                if(service){
                    let selected = this.team.filter((value) => value.id === service);
                    this.selected = selected[0];
                } else {
                    this.selected = {
                        name: '',
                        description: ''
                    }
                }
                this.modal = !this.modal;
            }
        }
    }
</script>
<style scoped>

    .img-proj {
        border-radius: 10px;
        -moz-box-shadow: 0 10px 16px -6px rgba(0,0,0,.5); 
        -webkit-box-shadow: 0 10px 16px -6px rgba(0,0,0,.5); 
        box-shadow:  10px 10px 16px -6px rgba(0,0,0,.5);
    }

    h4 {
  margin: 0;
}
.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}
.gallery .animation {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.img-gallery-preview {
    object-fit: cover;
}

@media (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

@media (max-width: 400px) {
  .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

@media (min-width: 768px) {
  .carousel-multi-item-2 .col-md-3 {
    float: left;
    width: 25%;
    max-width: 100%;
  }
}

.carousel-multi-item-2 .card img {
  border-radius: 2px;
}

figure {
  cursor: pointer;
}
</style>